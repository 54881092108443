export interface Agreement {
  id: number;
  name: string;
  description: string;
  content: string;
  created_at: string;
  reports?: AgreementType[];
}

export enum AgreementType {
  INVOICE = "invoice",
  PREPARATION = "preparation",
  CONFIRMATION = "confirmation",
  SALES_SUMMARY = "sales_summary",
  SERVICE_SUMMARY = "service_summary",
}
